<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading" style="background-color: transparent">
    <div v-if="$route.query.id" class="row mb-5">
      <div class="col-12">
        <button
          v-if="form.status !== '0'"
          type="button"
          class="btn btn-light shadow-sm"
          style="font-size: 14px; font-weight: bold; color: black"
          @click="goBack()"
        >
          กลับ
        </button>
      </div>
    </div>
    <div class="modal fade" tabindex="-1" id="kt_modal_1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">เพิ่มจำนวนสินค้า</h5>

            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span class="svg-icon svg-icon-2x"></span>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body">
            <label
              for="validationTooltip01"
              class="required form-label col-sm-10"
              >ระบุจำนวนสินค้า</label
            >
            <input
              class="form-control"
              v-model="amount"
              type="text"
              max-length="4"
              v-mask-number
              required
            />
          </div>

          <div class="modal-footer">
            <!-- <button
              type="button"
              class="btn btn-light"
              data-bs-dismiss="modal"
            >
              Close
            </button> -->
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              @click="saveProduct(amount)"
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal -->

    <form id="formItems" class="needs-validation" novalidate>
      <!-- form -->
      <div class="row">
        <div class="col-sm-7 mb-4">
          <div class="card shadow-sm mb-4 classcustomer">
            <div class="card-body">
              <div class="col-sm-12">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text"
                      id="basic-addon1"
                      style="border-radius: 5px 0px 0px 5px"
                      ><i
                        class="bi bi-person-plus-fill"
                        style="font-size: 22px"
                      ></i
                    ></span>
                  </div>

                  <input
                    v-model="searchCustomer"
                    type="text"
                    class="form-control"
                    placeholder="ค้นหาชื่อผู้ซื้อ"
                    aria-describedby="basic-addon2"
                    :disabled="isView"
                    list="datalistCustomers"
                  />
                  <!-- @keypress.enter.prevent="addCustomer()" -->
                  <datalist
                    id="datalistCustomers"
                    v-if="searchListCustomer.length >= 1"
                  >
                    <option
                      id="datalistCustomer11"
                      v-for="(item, index) in searchListCustomer"
                      :key="index"
                      :value="item.code"
                    >
                      {{ item.name }}
                      <hr style="color: LightGrey" class="mt-4 mb-0 pb-0" />
                    </option>
                  </datalist>
                  <button
                    :disabled="isView ? true : loadingAddCs ? true : false"
                    class="btn btn-light btn-primary"
                    style="font-weight: bold"
                    @click="saveCustomer()"
                  >
                    <!-- @click="addCustomer()" -->
                    <div
                      v-if="loadingAddCs"
                      class="spinner-grow spinner-grow-sm text-light"
                      role="status"
                    ></div>
                    เลือก
                  </button>
                </div>
              </div>

              <div v-if="this.customerDetails" class="col-sm-12 mt-6">
                <img src="media/svg/brand-logos/atica.svg" style="width: 30px;" />
              </div>

              <div v-if="this.customerDetails" class="col-sm-12 mt-4">
                <span style="font-size: 14px; font-weight: 600">
                  ชื่อลูกค้า
                </span>
                <br />
                <span style="font-size: 14px; font-weight: 400; color: #7e8299"
                  >คุณ{{ customerDetails.name }}</span
                >
              </div>
              <!-- <div
                v-if="this.customerDetails"
                class="col-sm-12 mt-4"
                style="font-size: 16px"
              >
                ส่วนลดลูกค้า : {{ customerDetails.discount.discount_name }} |
                {{ customerDetails.discount.discount_price }} %
              </div> -->
              <div v-if="this.customerDetails" class="col-sm-12 mt-4">
                <span style="font-size: 14px; font-weight: 600"> สายวิ่ง </span>
                <br />
                <span style="font-size: 14px; font-weight: 400; color: #7e8299"
                  >{{ customerDetails.route.code }} |
                  {{ customerDetails.route.name }}</span
                >
              </div>
            </div>
          </div>
          <div class="card shadow-sm classproduct" v-if="customerDetails">
            <div class="card-body">
              <!-- -->
              <div class="row text-sm-start d-flex justify-content-center mb-4">
                <!-- <hr style="color: LightGrey" class="mt-7 mb-0 pb-0" /> -->
                <!-- แบ่ง -->
                <div class="col-sm-12">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span
                        class="input-group-text"
                        id="basic-addon1"
                        style="border-radius: 5px 0px 0px 5px"
                        ><i class="bi bi-search" style="font-size: 21px"></i
                      ></span>
                    </div>

                    <input
                      v-model="searchProduct"
                      type="text"
                      class="form-control"
                      placeholder="ค้นหา Item code / ชื่อสินค้า
                      "
                      aria-describedby="basic-addon1"
                      :disabled="isView"
                      list="datalistOptions"
                    />
                    <datalist
                      id="datalistOptions"
                      v-if="searchListProduct.length >= 1"
                    >
                      <option
                        id="datalistOptions11"
                        v-for="(item, index) in searchListProduct"
                        :key="index"
                        :value="item.code"
                      >
                        {{ item.name }}
                        <hr style="color: LightGrey" class="mt-4 mb-0 pb-0" />
                      </option>
                    </datalist>
                    <button
                      :disabled="isView ? true : loadingAddPd ? true : false"
                      @keypress.enter.prevent="addProductItems()"
                      class="btn btn-light btn-primary"
                      style="font-weight: bold"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_1"
                    >
                      <div
                        v-if="loadingAddPd"
                        class="spinner-grow spinner-grow-sm text-light"
                        role="status"
                      ></div>
                      <!-- เปิดdialog -->
                      <!-- เพิ่ม -->
                      เลือก
                    </button>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="table-responsive">
                  <table
                    class="
                      table table-hover table-rounded table-striped
                      border
                      gy-7
                      gs-7
                    "
                  >
                    <thead>
                      <tr
                        style="width: 100%"
                        class="
                          fw-bold
                          fs-5
                          text-gray-800
                          border-bottom-2 border-gray-200
                          mw-200px
                        "
                      >
                        <th style="width: 15%" class="text-center">ลำดับ</th>
                        <th style="width: 15%" class="text-center">
                          รหัสสินค้า
                        </th>
                        <th style="width: 15%" class="text-center">
                          ชื่อสินค้า
                        </th>
                        <th style="width: 15%" class="text-center">จำนวน</th>
                        <th style="width: 15%" class="text-center">ราคาขาย</th>
                        <th style="width: 10%"></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(item, index) in productItems"
                        :key="index"
                        data-toggle="tooltip"
                        :title="item.searchBy"
                        class="text-center"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>
                          {{ item.item_code }}
                          <!-- <input
                            v-model="item.item_code"
                            class="
                              form-control form-control-solid
                              text-end
                              rounded-3
                            "
                            type="number"
                            disabled
                            min="0"
                            oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
                          /> -->
                        </td>
                        <td>{{ item.item_name }}</td>
                        <td>
                          <input
                            v-model="item.item_amt"
                            class="form-control text-end rounded-3"
                            type="number"
                            min="0"
                            oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
                            @blur="addQtyByIndex(index, item.item_amt)"
                          />
                        </td>

                        <td>
                          {{ item.item_price }}
                          <!-- <input
                            v-model="item.item_price"
                            class="form-control text-end"
                            type="number"
                            min="0"
                            oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
                          /> -->
                        </td>

                        <td>
                          <div class="row">
                            <div class="col-sm-12 text-end">
                              <button
                                type="button"
                                class="
                                  btn
                                  btn-link
                                  btn-color-muted
                                  btn-active-color-danger
                                  m-0
                                  p-0
                                "
                                @click="goToDeleteProductItem(item)"
                                :disabled="isView"
                              >
                                <i class="bi bi-trash3-fill"></i>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <hr style="color: LightGrey" />

            <div class="ps-9 pb-5" style="font-size: 16px; color: grey">
              สินค้าจำนวน
              <b style="color: black">{{ productItems.length }}</b> รายการ
            </div>
          </div>
        </div>

        <div class="col-sm-5">
          <div class="card shadow-sm classdetail">
            <div class="card-body pb-2">
              <!-- -->
              <div class="row text-sm-start d-flex justify-content-center mb-4">
                <div class="row" style="color: grey">
                  <div class="col-sm-6 mt-4 text-start" style="font-size: 16px">
                    มูลค่ารวมก่อนภาษี
                  </div>

                  <div
                    class="col-sm-6 mt-4 text-end"
                    style="font-size: 16px"
                    v-if="productItems.length"
                  >
                    {{ form.total_price_before_vat.toFixed(2) }}
                  </div>
                </div>

                <hr style="color: LightGrey" class="mt-4 mb-0 pb-0" />

                <div class="row" style="color: grey">
                  <div class="col-sm-6 mt-4 text-start" style="font-size: 16px">
                    ภาษี
                  </div>

                  <div class="col-sm-6 mt-4 text-end" style="font-size: 16px">
                    {{ form.vat.toFixed(2) }}
                  </div>
                </div>

                <hr style="color: LightGrey" class="mt-4 mb-0 pb-0" />

                <div class="row" style="color: grey">
                  <div class="col-sm-6 mt-4 text-start" style="font-size: 16px">
                    มูลค่าก่อนรวมส่วนลด
                  </div>

                  <div class="col-sm-6 mt-4 text-end" style="font-size: 16px">
                    {{ form.total_price_summary.toFixed(2) }}
                  </div>
                </div>

                <hr style="color: LightGrey" class="mt-4 mb-0 pb-0" />
                <div
                  class="row"
                  style="color: grey; font-weight: 600"
                  v-if="customerDetails.discount"
                >
                  <div
                    class="col-sm-6 mt-4 text-start"
                    style="font-size: 16px"
                    v-if="customerDetails.discount"
                  >
                    {{ customerDetails.discount.discount_name }}
                  </div>
                  <div
                    class="col-sm-6 mt-4 text-end"
                    style="font-size: 16px"
                    v-if="customerDetails.discount"
                  >
                    {{ customerDetails.discount.discount_price }} %
                  </div>

                  <hr style="color: LightGrey" class="mt-4 mb-0 pb-0" />
                </div>
                <div class="row" style="color: grey; font-weight: 600" v-else>
                  <div
                    class="col-sm-6 mt-4 text-start"
                    style="font-size: 16px"
                    v-if="customerDetails.discount"
                  >
                    ไม่มีส่วนลด
                  </div>
                  <div
                    class="col-sm-6 mt-4 text-end"
                    style="font-size: 16px"
                    v-if="customerDetails.discount"
                  >
                    -
                  </div>
                </div>

                <div
                  class="row pb-4"
                  style="
                    background-color: LightGrey;
                    border-radius: 7px;
                    font-size: 20px;
                    font-weight: bold;
                  "
                >
                  <div class="col-sm-6 mt-4 text-start">มูลค่ารวมสุทธิ</div>

                  <div class="col-sm-6 mt-4 text-end" style="font-size: 16px">
                    {{ form.final_price.toFixed(2) }}
                  </div>
                </div>

                <hr style="color: LightGrey" class="mt-7 mb-3 pb-0" />

                <div class="row" style="color: grey">
                  <div class="col-sm-4 mt-4 text-start" style="font-size: 16px">
                    รับเงินสด
                  </div>

                  <div class="col-sm-8 mt-4 text-end" style="font-size: 16px">
                    <input
                      v-model="form.cash"
                      @input="handleCash(form.cash)"
                      class="form-control text-end"
                      type="number"
                      min="0"
                      oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
                      :disabled="isView"
                    />
                  </div>
                </div>

                <hr style="color: LightGrey" class="mt-7 mb-0 pb-0" />

                <div class="row" style="color: grey">
                  <div class="col-sm-6 mt-4 text-start" style="font-size: 16px">
                    เงินทอน
                  </div>

                  <div
                    class="col-sm-6 mt-4 text-end"
                    style="font-size: 16px; font-weight: bold; color: black"
                  >
                    {{ form.change.toFixed(2) }}
                  </div>
                </div>

                <button
                  @click="handleApprove"
                  type="submit"
                  class="btn btn-lg btn-light btn-primary mt-5"
                  style="font-size: 20px; font-weight: bold"
                >
                  <!-- :disabled="
                    isView
                      ? true
                      : productItems.length < 0
                      ? true
                      : form.change > 0
                      ? false
                      : true
                  " -->
                  Pay Now
                </button>

                <button
                  @click="handleApprove"
                  type="submit"
                  class="btn btn-lg btn-light btn-primary mt-5 btn-block"
                  style="font-size: 20px; font-weight: bold"
                >
                  ยกเลิกการขาย
                </button>

                <div class="col-12">
                  <button
                    @click="handleApprove"
                    type="submit"
                    class="
                      btn btn-lg btn-light btn-primary btn-block
                      mt-5
                      col-sm-5
                    "
                    style="font-size: 16px; font-weight: bold; float: left"
                  >
                    พิมพ์ใบเสร็จซ้ำ
                  </button>
                  <button
                    @click="handleApprove"
                    type="submit"
                    class="
                      btn btn-lg btn-light btn-success btn-block
                      mt-5
                      col-sm-5
                    "
                    style="font-size: 16px; font-weight: bold; float: right"
                  >
                    บันทึกแบบรวดเร็ว
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <DialogConfirmByPass
      :dialogConfirmByPass="dialogConfirmByPass"
      :loadingUpdate="loadingUpdate"
      @submitConfirmPass="submitConfirmPass"
      @closeDialogConformByPass="closeDialogConformByPass"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";

// import saleApi from "@/api/sale/";
import transactionApi from "@/api/transaction/";
import customerAPI from "@/api/customer/";
import whApi from "@/api/warehouse/";

import Loader from "./components/loaderPOS.vue";
import DialogConfirmByPass from "./components/dialogConfirmByPass.vue";

import { Decode, Encode } from "@/services";
import Multiselect from "@vueform/multiselect";

export default {
  components: {
    DialogConfirmByPass,
    Loader,
    Multiselect,
  },
  setup() {
    document.title = "bem-document-demo POS";
  },
  data: () => ({
    donut: null,
    loading: false,
    loadingUpdate: false,
    loadingAddPd: false,
    loadingAddCs: false,

    item_amt: 1,

    searchProduct: null,
    searchCustomer: "",
    searchBy: "",

    customerDetails: "",

    isSubmit: false,
    dialogConfirmByPass: false,

    form: {
      // selectMainProduct: "",
      // selectSecondaryProduct: "",
      // ProductName: "",
      // detail: "",
      // barcode: "",
      // pog: "",
      // retailPrice: "",
      // proPrice: "",
      // wholesalePrice: "",
      // min: "",
      // max: "",
      // customerId: "",
      // customer_code: "",
      // products: [],
      total_price_before_vat: 0,
      vat: 0,
      total_price_summary: 0,
      final_price: 0,
      cash: 0,
      change: 0,
    },

    ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,
    customers: [],
    products: [],
    options: ["Batman", "Robin", "Joker"],
    productsInPut: [],

    productItems: [],
    isView: false,
    amount: null,
    searchListProduct: [],
    searchListCustomer: [],
  }),

  watch: {
    searchProduct(val) {
      console.log("คำค้นหา", val);
      this.addProductItems(val);
    },
    searchCustomer(val) {
      console.log("customers", val);
      this.addCustomer(val);
    },
  },

  async created() {
    await this.getAllCustomer();
    // await this.getAllProduct();
    // await this.getOne();
    // this.$route.query.id ? (this.isView = true) : (this.isView = false);
  },

  methods: {
    async getAllCustomer() {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await customerAPI.customer.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.customers = responseData.data;
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    async getAllProduct() {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.product.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.products = responseData.data;
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },

    cancel() {
      this.$router.go(-1);
    },
    goBack() {
      this.$router.go(-1);
    },

    async addProductItems(searchProduct) {
      // console.log("addProductItems", searchProduct, this.amount);
      let responseData = [];
      try {
        responseData = await whApi.product.search({ search: searchProduct });
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.searchListProduct = responseData.data;
        console.log("this.searchListProduct", this.searchListProduct);
        // this.types = responseData.data;
        // this.loading = false;
      }
      // if (item) {
      //   this.productItems.push({
      //     item_code: item.code,
      //     item_name: item.name,
      //     item_amt: "mock",
      //     item_price: item.productPrices[0].unit_price,
      //   });
      // }
      ///////////////////////////////////////
      // if (item) {
      //   const newItem = item[0];
      //   this.productItems.push({
      //     ...newItem,
      //     item_amt: this.item_amt,
      //     item_promo_price: newItem.product_promotion_price,
      //     item_price: newItem.product_retail_price,
      //     searchBy: `ค้นหาโดย ${method}`,
      //   });
      //   this.sumPayout();
      //   this.form.cash = 0;
      //   this.form.change = 0;
      // } else {
      //   if (this.searchProduct.length === 13) {
      //     this.loadingAddPd = true;
      //     setTimeout(() => {
      //       this.searchPdByBarcode();
      //     }, 500);
      //   }
      //   if (this.searchProduct.length >= 5 || this.searchProduct.length <= 12) {
      //     this.loadingAddPd = true;
      //     setTimeout(() => {
      //       this.searchPdByCode();
      //     }, 500);
      //   }
      // }
    },

    async saveCustomer() {
      let donut = document.getElementById("datalistCustomer11");
      this.customerDetails = this.searchListCustomer[0];
      console.log("this.customerDetails", this.customerDetails);
    },
    async saveProduct(amount) {
      let donut = document.getElementById("datalistOptions11");
      // console.log("code", donut);
      // console.log("ของที่เลือก", this.searchListProduct);
      let arr;
      // this.searchListProduct.forEach((element) => {
      //   if (element.code == donut) {
      //     arr = element;
      //   }
      // });

      arr = this.searchListProduct[0];
      console.log("arr", arr);
      this.productItems.push({
        item_code: arr.code,
        item_name: arr.name,
        item_amt: amount * 1,
        item_price: arr.price * 1,
      });
      console.log("productItems", this.productItems);
      this.sumPayout();
    },

    async addCustomer(searchCustomer) {
      let responseData = [];
      try {
        responseData = await customerAPI.customer.search({
          search: searchCustomer,
        });
      } catch (error) {
        console.log(error);
      }
      console.log("responseData.data", responseData.data);
      if (responseData.response_status === "SUCCESS") {
        this.searchListCustomer = responseData.data;

        // this.types = responseData.data;
        // this.loading = false;
      }
    },

    goToDeleteProductItem(item) {
      Swal.fire({
        icon: "question",
        html: `<span style="font-size: 16px">ต้องการลบสินค้ารายการ <b style="font-size: 18px">${item.item_name}</b> หรือไม่ ?</span>`,
        showCancelButton: true,
        width: 450,
      }).then((result) => {
        if (result.isConfirmed) {
          this.confirmDeleteProductItem(item);
        }
      });
    },
    confirmDeleteProductItem(item) {
      const indexOfItem = this.productItems.indexOf(item);
      this.productItems.splice(indexOfItem, 1);
      this.sumPayout();
    },

    addQtyByIndex(index, item_amt) {
      this.productItems[index] = {
        ...this.productItems[index],
        item_amt: item_amt,
      };
      this.sumPayout();
      // this.handleCash(this.form.cash);
    },

    sumPayout() {
      console.log("เข้า", this.productItems);
      let total = 0;
      let vat = 0;

      this.productItems.forEach((element) => {
        if (element.item_price && element.item_amt) {
          total += parseInt(element.item_price) * parseInt(element.item_amt);
          vat +=
            (parseInt(element.item_price) * parseInt(element.item_amt) * 7) /
            100;
        }
      });

      this.form.total_price_before_vat = total - vat;
      this.form.vat = vat;
      this.form.total_price_summary = total;
      this.form.final_price = this.customerDetails.discount.discount_price
        ? total - parseFloat(this.customerDetails.discount.discount_price)
        : total;
    },

    handleCash(val) {
      if (val && val > this.form.final_price) {
        this.form.change = parseFloat(val) - parseFloat(this.form.final_price);
      }
    },

    handleApprove(item) {
      if (!this.searchCustomer) {
        Swal.fire({
          icon: "warning",
          title: "ชำระเงินไม่สำเร็จ !",
          text: "กรุณาเลือกผู้ซื้อ",
        });
        // } else if (this.form.final_price === 0) {
        //   Swal.fire({
        //     icon: "warning",
        //     title: "ชำระเงินไม่สำเร็จ !",
        //     text: "มีรายการสินค้าไม่ได้กรอก item_amt",
        //   });
      } else if (this.form.cash === 0) {
        Swal.fire({
          icon: "warning",
          title: "ชำระเงินไม่สำเร็จ !",
          text: "กรุณากรอกช่องรับเงินสด",
        });
      } else if (this.form.cash < this.form.final_price) {
        Swal.fire({
          icon: "warning",
          title: "ชำระเงินไม่สำเร็จ !",
          text: "มูลค่าเงินสดต้องมากกว่ามูลค่ารวมสุทธิ",
        });
      } else {
        this.submit();
        //   // this.dialogConfirmByPass = true;
      }
    },
    async submitConfirmPass(isApprove) {
      // this.statusApprove(isApprove);
      this.submit();
      this.dialogConfirmByPass = false;
    },
    closeDialogConformByPass() {
      this.dialogConfirmByPass = false;
    },
    async submit() {
      let companyId = localStorage.getItem("companyId");
      let responseData = [];
      let userId = Decode.decode(localStorage.getItem("boss"));

      this.form.products = this.productItems;

      this.form = {
        ...this.form, //product
        customerId: this.customerDetails.id, //customer
        companyId: companyId, //บริษัท
        userId: userId, //sale
        vat: this.form.vat, // ภาษี
        discount: this.customerDetails.discount
          ? this.customerDetails.discount.discount_price * 1
          : 0, // ส่วนลดลูกค้า
        final_price: this.form.final_price, // ราคารวมสุทธิ
        cash: this.form.cash, // รับเงินสด
        change: this.form.change, // เงินทอน

        // products
        // userId
        // customerId
        // total_price
        // discount
        // tax
        // change
      };
      console.log("final data", this.form);
      try {
        responseData = await transactionApi.transaction.create(this.form);
      } catch (error) {}
      if (responseData.response_status === "SUCCESS") {
        // this.updateProduct();
        console.log("submit", responseData.data);
        Swal.fire({
          icon: "success",
          title: "ชำระค่าสินค้าเรียบร้อยแล้ว",
          showConfirmButton: true,
          confirmButtonText: "ตกลง",
          timer: 4000,
        }).then((result) => {
          this.clearForm();
        });
      }
    },

    // update item
    async updateProduct() {
      let countArr = 0;
      for (const element of this.form.products) {
        countArr += 1;
        await this.getOneProduct(element.id, element.item_amt);
      }
      // if (
      //   countArr === this.form.products.length &&
      //   this.statusAllProcess === 1
      // ) {
      //   this.$router.push({
      //     path: "/sale/SA5",
      //     query: {},
      //   });
      // }
    },
    async getOneProduct(id, item_amt) {
      let getOneResponse = {};
      try {
        getOneResponse = await whApi.product.getOne(id);
      } catch (error) {
        console.log(error);
      }
      if (getOneResponse.response_status === "SUCCESS") {
        this.updateProductAmountRecieve(item_amt, getOneResponse.data);
      }
    },
    async updateProductAmountRecieve(item_amt, item) {
      let updateResponse = [];
      let sumQty = parseFloat(item.product_bring_qty) - parseFloat(item_amt);

      const body = {
        ...item,
        product_bring_qty: sumQty,
      };

      updateResponse = await whApi.product.update(item.id, body);
      if (updateResponse.response_status !== "SUCCESS") {
        this.statusAllProcess *= 0;

        Swal.fire({
          icon: "error",
          html: `ชำระค่าสินค้าไม่สำเร็จ !`,
          timer: 4000,
        });
      } else {
        this.statusAllProcess *= 1;
      }
    },
    // update item

    clearForm() {
      this.form = {
        // selectMainProduct: "",
        // selectSecondaryProduct: "",
        // ProductName: "",
        // detail: "",
        // barcode: "",
        // pog: "",
        // retailPrice: "",
        // proPrice: "",
        // wholesalePrice: "",
        // min: "",
        // max: "",

        cash: 0,
        change: 0,

        total_price_before_vat: 0,
        vat: 0,
        total_price_summary: 0,
        final_price: 0,
      };

      this.searchProduct = "";
      this.searchCustomer = "";
      this.customerDetails = "";

      this.productItems = [];

      this.getAllCustomer();
    },

    // view
    async getOne() {
      if (this.$route.query.id) {
        this.loading = true;
        let responseData = [];

        try {
          responseData = await saleApi.pos.getOne(this.$route.query.id);
        } catch (error) {
          console.log(error);
        }
        if (responseData.response_status === "SUCCESS") {
          this.form = {
            ...responseData.data,
            total_price_before_vat: parseFloat(
              responseData.data.total_price_before_vat
            ),
            vat: parseFloat(responseData.data.vat),
            total_price_summary: parseFloat(
              responseData.data.total_price_summary
            ),
            final_price: parseFloat(responseData.data.final_price),
            change: parseFloat(responseData.data.change),
          };
          this.setProductByGetOne(responseData.data.pointOfSaleItems);
          this.loading = false;
        } else {
          this.loading = false;
          Swal.fire({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
            text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
          });
        }
      }
    },

    setProductByGetOne(item) {
      item.forEach((element) => {
        this.productItems.push({
          ...element.product,
          item_amt: element.item_amt,
        });
      });
    },

    // search product
    async searchPdByCode() {
      let responseSearch = [];

      responseSearch = await whApi.product.search(
        {
          search: this.searchProduct,
        },
        "1",
        "code"
      );

      if (
        responseSearch.response_status === "SUCCESS" &&
        responseSearch.data.length > 0
      ) {
        this.addProductItems(responseSearch.data, "Code");
        this.loadingAddPd = false;
        this.searchProduct = "";
      } else {
        this.searchPdByBarcode();
      }
    },

    async searchPdByBarcode() {
      let responseSearch = [];

      responseSearch = await whApi.product.search(
        {
          search: this.searchProduct,
        },
        "1",
        "barcode"
      );

      if (
        responseSearch.response_status === "SUCCESS" &&
        responseSearch.data.length > 0
      ) {
        this.addProductItems(responseSearch.data, "Barcode");
        this.loadingAddPd = false;
        this.searchProduct = "";
      } else {
        Swal.fire({
          icon: "error",
          title: `ไม่พบข้อมูลสินค้า !`,
          confirmButtonText: "ตกลง",
          width: 450,
          timer: 2000,
        }).then((result) => {
          this.loadingAddPd = false;
        });
      }
    },

    //search customer
    async onSearchCustomer() {
      let responseSearch = [];

      responseSearch = await customerAPI.customer.search(
        {
          search: this.searchCustomer,
        },
        "1"
      );

      if (
        responseSearch.response_status === "SUCCESS" &&
        responseSearch.data.length > 0
      ) {
        this.loadingAddCs = false;
        this.addCustomer(responseSearch.data);
        this.form.customer_code = responseSearch.data[0].customer_code;
        console.log(responseSearch.data);
      }
    },
  },
};
</script>
<style>
.vs__dropdown-toggle {
  border-radius: 0px 5px 5px 0px;

  height: 37.5px;
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>